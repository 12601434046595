:root {
  --amplify-background-color: #f5f5f5e6!important;
  --amplify-primary-color: #2e7d32;
  --amplify-primary-tint: #2e7d32;
  --amplify-primary-shade: #2e7d32;
  --background-color: #ffffff85 !important;
  --amplify-secondary-contrast: #ffffff85 !important;
   
}

:host {

  --background-color: #ffffff85 !important;
}

amplify-sign-in {

    width: 100%;
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
    background-size: cover !important;
   background-position: center !important;
    background-repeat: no-repeat !important;
 background: url(./vendor/Banner2-min.jpg);

} 



.button {
  border-radius: 5px;

}

#tableWrapper::-webkit-scrollbar {
  display: none;
}